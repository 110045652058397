import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconApproved = createIconComponent({
  content: (
    <g>
      <path d="M53.365 206.919a302.192 302.192 0 0 1 34.904 34.59c28.93 28.93 44.653 43.08 72.325 72.954 14.78 16.352 23.584 15.094 38.678 0 79.243-80.5 159.743-160.058 238.672-239.93 15.723-16.037 24.842-21.697 43.395-1.887 40.25 43.395 41.508 42.137 0 83.331-94.337 94.337-188.674 187.101-280.81 283.01-16.665 16.98-25.785 16.038-41.507 0A4912.35 4912.35 0 0 0 11.228 291.193c-16.352-15.723-13.522-23.27 0-38.992 13.521-15.723 37.734-48.741 41.508-43.71" />
    </g>
  ),
  height: 512,
  width: 512,
})
IconApproved.displayName = 'IconApproved'
export default IconApproved
