import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconIconSearch = createIconComponent({
  content: (
    <g>
      <g transform="translate(1 5)">
        <path d="M192 376.37c-76.196.438-145.135-45.121-174.603-115.39C-12.07 190.71 3.75 109.607 57.467 55.565s134.724-70.352 205.17-41.308C333.08 43.3 379.055 111.963 379.076 188.16c.218 103.594-83.484 187.802-187.077 188.21zm0-326.4c-56.022-.48-106.794 32.902-128.561 84.525-21.767 51.623-10.224 111.279 29.228 151.057 39.452 39.778 99.01 51.81 150.811 30.47 51.8-21.342 85.598-71.838 85.58-127.862.192-75.958-61.1-137.757-137.058-138.19z" />
        <path d="M308.732 321.23l17.406-17.406L432 409.686l-17.406 17.405z" />
        <rect
          width="215.089"
          height="69.12"
          x="303.55"
          y="371.624"
          rx="7.02"
          transform="rotate(45 411.094 406.184)"
        />
      </g>
    </g>
  ),
  height: 512,
  width: 512,
})
IconIconSearch.displayName = 'IconIconSearch'
export default IconIconSearch
