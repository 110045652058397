import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconListing = createIconComponent({
  content: (
    <g>
      <path d="M0 57h512v64H0V57zm0 160h512v64H0v-64zm0 160h512v64H0v-64z" />
    </g>
  ),
  height: 512,
  width: 512,
})
IconListing.displayName = 'IconListing'
export default IconListing
