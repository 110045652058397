export const NAME = 'resources'

export const REQUEST_ACTION = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
}

export const REQUEST_STATUS = {
  IDLE: 'idle',
  PENDING: 'pending',
  FAILED: 'failed',
  SUCCEEDED: 'succeeded',
}
