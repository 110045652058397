import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconMapIconCharity = createIconComponent({
  content: (
    <g>
      <g fill="none">
        <path
          fill="#FF6955"
          d="M258.883 7.499c98.016 0 177.472 79.669 177.472 177.941 0 142.347-177.472 320.288-177.472 320.288S81.42 327.787 81.42 185.44c0-98.272 79.456-177.941 177.462-177.941"
        />
        <g fill="#FFF">
          <path d="M274.584 226.89l-52.427 53.76s-10.421 7.233-17.76-2.58c-6.613-8.843 16.576-29.238 16.576-29.238l31.414-31.2s13.173 9.44 23.21 8.181m5.174-.928c5.536-1.333 13.45-4.362 20.885-15.936 3.99-6.218 5.43-13.173 2.827-25.792-2.208-10.656-18.443-24.938-18.443-24.938l-7.435-6.55s11.755-9.664 31.414-9.962c8.074-.118 21.834.885 31.232 8.16 9.386 7.274 27.541 24.224 27.84 43.914.277 18.592-6.955 36.224-21.067 51.03-16.81 17.632-70.73 71.605-70.73 71.605s-10.774 12.779-16.182 11.947c-3.104-.47-5.28-2.006-6.848-4-5.12-6.55 5.632-16.118 5.632-16.118l53.792-53.962-8.49-8.096-58.347 59.882s-6.827 5.248-12.576 1.451c-12.555-8.288-4.694-19.307-4.694-19.307l57.472-58.037-7.04-6.133m-82.794 34.869s-7.691 3.787-14.048-3.296c-6.454-7.19-1.739-13.099-1.739-13.099l46.57-47.306 17.025 16.864-47.808 46.837z" />
          <path d="M285.645 178.304c-2.218-2.101-32.853-32.47-32.853-32.47s-17.077-16.298-42.763-13.802c-25.685 2.496-36.234 8.939-50.848 25.237-14.602 16.299-16.554 41.675-13.034 54.944 3.52 13.27 8.469 24.843 20.074 37.334 0 0 .523-10.251 3.915-13.547 3.392-3.296 60.608-61.024 60.608-61.024l-16.299-16.565-1.29 1.877L260.077 208s13.43 11.968 26.464-1.835c13.035-13.802 1.323-25.76-.896-27.861z" />
        </g>
      </g>
    </g>
  ),
  height: 512,
  width: 512,
})
IconMapIconCharity.displayName = 'IconMapIconCharity'
export default IconMapIconCharity
