import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconIconTypeVolunteer = createIconComponent({
  content: (
    <g>
      <path d="M256.25 0c35.668 0 64.583 28.915 64.583 64.583 0 35.669-28.915 64.584-64.583 64.584s-64.583-28.915-64.583-64.584C191.667 28.915 220.582 0 256.25 0zM357.5 361h-32.667V238.333c0-5.25-1.083-9.75-6.25-9.75-5.166 0-5.75 4.5-5.75 9.75V512h-47.166V361a9.417 9.417 0 0 0-18.834 0v151h-47.25V238.333c0-5.25-1.333-9.75-6.583-9.75s-5.417 4.5-5.417 9.75V361H155V228.917c0-52.084 47.417-92.084 99.5-92.084s103 39.917 103 92.084V361z" />
    </g>
  ),
  height: 512,
  width: 512,
})
IconIconTypeVolunteer.displayName = 'IconIconTypeVolunteer'
export default IconIconTypeVolunteer
