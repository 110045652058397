import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconMyDonations = createIconComponent({
  content: (
    <g>
      <path d="M248.212 102.086c-.663-.904-.663-2.416-.663-5.16h-.676c2.03-15.562 3.721-31.461 5.75-47.022 1.016-8.12 4.398-15.9 4.398-24.019a54.125 54.125 0 0 1 10.487-21.988A9.472 9.472 0 0 1 276.98.176c7.442 0 9.81 5.074 6.428 10.825a92.352 92.352 0 0 0-13.87 32.137c-1.691 11.163-3.721 26.386-3.721 33.828 0 7.443.338 17.93 0 22.327-.086 1.116-.259 1.949-.52 2.57a51.758 51.758 0 0 1 39.76 30.92 292.954 292.954 0 0 1 11.164 33.829 638.342 638.342 0 0 0 27.063 81.526 154.258 154.258 0 0 0 25.371 40.256 140.388 140.388 0 0 1 33.829 67.657 123.812 123.812 0 0 1-9.134 72.054 130.24 130.24 0 0 1-93.028 76.791c-9.472 2.368-19.282 5.074-29.093 5.074L257.36 512l.339-.338a174.555 174.555 0 0 1-101.485-33.829 129.225 129.225 0 0 1-46.684-83.218 121.782 121.782 0 0 1 30.446-101.485 226.65 226.65 0 0 0 45.33-81.188c7.104-22.327 13.193-44.992 19.959-67.657a56.155 56.155 0 0 1 27.063-36.535 51.737 51.737 0 0 1 15.885-5.664zm31.474-20.722h-.338c-.77-20.904 11.893-39.965 31.46-47.36a110.28 110.28 0 0 1 37.888-10.825h30.446c3.721 0 3.721.677 3.721 3.721a145.462 145.462 0 0 1-33.828 67.657c-17.364 17.795-45.485 19.268-64.613 3.383a8.119 8.119 0 0 1-2.706-4.736c-2.03-4.736-2.03-9.134-2.03-11.84z" />
    </g>
  ),
  height: 512,
  width: 512,
})
IconMyDonations.displayName = 'IconMyDonations'
export default IconMyDonations
