import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconIconHeart = createIconComponent({
  content: (
    <g>
      <path
        fill="#E63636"
        fillRule="evenodd"
        d="M512 183.586v13.081c-.518 4.962-.91 9.94-1.578 14.88-2.379 17.64-8.362 34.006-17.709 49.138-10.608 17.177-24.555 31.329-40.184 43.906-16.358 13.165-34.02 24.447-51.803 35.545-17.558 10.96-35.154 21.868-51.499 34.633-28.311 22.107-54.186 46.69-76.123 75.238-5.743 7.476-11.039 15.268-14.91 23.924-.7 1.564-1.247 3.196-1.975 5.077-.8-2.005-1.402-3.687-2.13-5.313-3.685-8.23-8.552-15.769-13.883-22.99-19.917-26.977-43.855-49.947-70.405-70.288-15.697-12.028-32.592-22.238-49.458-32.49-16.208-9.85-32.434-19.665-47.793-30.836-16.718-12.16-32.2-25.621-44.824-42.127-16.539-21.62-25.713-45.997-27.408-73.182-.661-10.588-.23-21.142.727-31.692 1.51-16.66 4.812-32.933 10.506-48.678 8.703-24.066 21.947-45.1 41.682-61.704 17.228-14.495 36.949-23.522 59.32-26.566 3.354-.457 6.73-.765 10.094-1.142h10.387c.817.118 1.63.296 2.452.347 9.445.589 18.67 2.36 27.67 5.253 25.384 8.152 45.998 23.274 63.094 43.454 11.764 13.886 20.962 29.374 28.313 45.988.367.83.787 1.636 1.249 2.59.246-.367.332-.465.385-.578.4-.873.795-1.747 1.188-2.623 10.404-23.228 24.408-43.884 43.926-60.444 25.582-21.707 54.948-33.105 88.811-31.702 17.095.708 33.237 5.12 48.363 13.081 24.997 13.158 42.916 33.122 55.29 58.25 10.587 21.499 15.776 44.426 17.493 68.222.234 3.25.487 6.499.732 9.748"
      />
    </g>
  ),
  height: 512,
  width: 512,
})
IconIconHeart.displayName = 'IconIconHeart'
export default IconIconHeart
