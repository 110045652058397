import {fetch} from 'redux-simple-auth'
import {API_SERVER_URL} from '../../app-constants'
import {handleErrors} from '../../utils/handleErrors'
import {processValidationErrors} from '../../utils/formValidations'
import * as actionTypes from './actionTypes'

/**
 * Update the profile settings of the authenticated user
 * @param {*} data
 * @param {*} meta
 * @returns Promise<object>
 */
export const updateProfileSettings = (data, meta) => {
  const id = '$create'
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.UPDATE_PROFILE_SETTINGS_LOADING,
        meta: {...meta, id},
      })

      dispatch(
        fetch(`${API_SERVER_URL}/v1/profiles/settings`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }),
      )
        .then(processValidationErrors)
        .then(json => {
          dispatch({
            type: actionTypes.UPDATE_PROFILE_SETTINGS_RECEIVED,
            payload: json,
            meta: {...meta, id},
          })
          return resolve(json)
        })
        .catch(error => {
          dispatch({
            type: actionTypes.UPDATE_PROFILE_SETTINGS_REJECTED,
            payload: error.message,
            meta: {...meta, id},
          })
          return reject(error)
        })
    })
  }
}

/**
 *
 * @param {*} id
 * @param {*} isSelected
 */
export const getProfileSettings = (id, isSelected) => {
  return dispatch => {
    dispatch({
      type: actionTypes.GET_PROFILE_SETTINGS_LOADING,
      meta: {id, isSelected},
    })

    dispatch(
      fetch(`${API_SERVER_URL}/v1/profiles/settings`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch({
          type: actionTypes.GET_PROFILE_SETTINGS_RECEIVED,
          payload: json,
          meta: {id},
        })
        return json
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_PROFILE_SETTINGS_REJECTED,
          payload: error.message,
          meta: {id},
        })
      })
  }
}
