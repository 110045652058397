import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconMyProfile = createIconComponent({
  content: (
    <g>
      <path d="M383.38 126.785c0 51.28-30.89 97.51-78.267 117.134-47.376 19.624-101.909 8.777-138.17-27.483-36.26-36.26-47.107-90.793-27.483-138.17C159.084 30.89 205.315 0 256.594 0 326.616 0 383.38 56.764 383.38 126.785zM25.554 430.275c25.622-94.537 85.701-142.69 171.403-143.13 98.954 0 204.977-25.623 276.1 88.351a132.528 132.528 0 0 1 19.88 54.778A438.226 438.226 0 0 1 504.863 512H7a378.588 378.588 0 0 1 18.554-81.726z" />
    </g>
  ),
  height: 512,
  width: 512,
})
IconMyProfile.displayName = 'IconMyProfile'
export default IconMyProfile
