// @flow
import {getStatus} from 'redux-resource'

/**
 * Returns the user with the given identifier
 */
export const getUserById = (state: State, id: Id) => {
  return state.resources.users.resources[id]
}

/**
 * Returns the fetch status of a inflight request
 */
export const getFetchStatusById = (state: State, id: Id) => {
  return getStatus(state.resources.users, `requests[${id}].status`, true)
}

/**
 * Returns whether the donation with the given identifier is loaded
 */
export const isUserLoaded = (id, state) => {
  const fetchStatus = getFetchStatusById(state, id)
  return fetchStatus.succeeded
}

/**
 * Returns whether the donation with the given identifier is loaded
 */
export const isUserLoading = (id, state) => {
  const fetchStatus = getFetchStatusById(state, id, true)
  return fetchStatus.pending
}

export default {
  getUserById,
  getFetchStatusById,
  isUserLoading,
  isUserLoaded,
}
