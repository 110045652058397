import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconIconTypeTransporter = createIconComponent({
  content: (
    <g>
      <g fill="none" fillRule="evenodd" transform="translate(7 37)">
        <path d="M129.682 178.087h228.1c20.034 0 43.334-14.024 44.15-34.653l-21.444-83.478c-3.414-18.848-15.286-37.695-37.918-37.695H144.893c-21.073 0-34.133 17.14-37.769 37.101l-21.593 82.885c.816 21 24.116 35.84 44.15 35.84z" />
        <circle
          cx="115.583"
          cy="278.335"
          r="23.374"
          fill="#000"
          fillRule="nonzero"
          opacity=".5"
        />
        <circle
          cx="373.661"
          cy="278.335"
          r="23.374"
          fill="#000"
          fillRule="nonzero"
        />
        <path
          fill="#000"
          fillRule="nonzero"
          d="M468.937 133.565h-44.521c-1.781 0-3.34-2.003-4.824-1.261l-18.476-75.39C395.996 29.014 376.258 0 343.46 0H145.784c-32.65 0-52.462 27.678-57.433 54.91l-18.922 77.84c-1.558-.965-3.19.816-5.12.816H19.787C7.841 133.565.42 138.388.42 150.78v10.686c-.59 11.643 8.322 21.582 19.96 22.26l2.969.594a87.114 87.114 0 0 0-3.042 27.38l-5.046 72.2v115.237c0 17.735 11.205 38.66 28.939 38.66h33.688c17.66 0 33.837-20.925 33.837-38.66v-35.543h274.55v35.543c0 17.735 7.42 38.66 25.08 38.66h33.69c17.734 0 32.129-17.66 32.129-35.395v-119.17l-6.159-70.344a91.789 91.789 0 0 0-4.304-28.345l6.382-.594c11.946-.52 24.56-10.388 24.56-22.26V150.78c-.073-12.392-16.324-17.215-28.716-17.215zM107.124 59.362c3.636-19.663 16.77-37.101 37.77-37.101H342.57c22.26 0 34.504 18.847 37.918 37.695l21.444 83.478c-.816 20.629-24.116 34.653-44.15 34.653h-228.1c-20.035 0-43.335-14.84-44.151-35.84l21.593-82.885zm8.533 262.753c-24.015 0-43.483-19.468-43.483-43.483 0-24.015 19.468-43.483 43.483-43.483 24.015 0 43.483 19.468 43.483 43.483-.244 23.882-19.674 43.113-43.557 43.112l.074.37zm183.801-10.463H189.786a10.092 10.092 0 1 1 0-20.109h109.672a10.092 10.092 0 0 1 0 20.11zm0-43.26H189.786a10.092 10.092 0 1 1 0-20.11h109.672a10.092 10.092 0 0 1 0 20.11zm74.203 53.649c-24.015 0-43.483-19.468-43.483-43.483 0-24.015 19.468-43.483 43.483-43.483 24.015 0 43.483 19.468 43.483 43.483-.163 23.899-19.584 43.186-43.483 43.186v.297z"
        />
        <path
          fill="#000"
          fillRule="nonzero"
          d="M115.583 301.635a23.374 23.374 0 1 1 23.3-23.374c0 12.88-10.42 23.333-23.3 23.374z"
        />
      </g>
    </g>
  ),
  height: 512,
  width: 512,
})
IconIconTypeTransporter.displayName = 'IconIconTypeTransporter'
export default IconIconTypeTransporter
