import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconIconTypeCharity = createIconComponent({
  content: (
    <g>
      <path d="M295.304 246.941L179.2 365.002s-23.04 15.887-39.304-5.647c-14.607-19.426 36.669-64.226 36.669-64.226l69.647-68.894s29.139 20.706 51.426 17.996m11.068-1.732c12.273-2.937 30.118-9.563 46.23-35.012 8.81-13.628 12.048-28.913 6.25-56.696-4.894-23.417-40.81-54.815-40.81-54.815l-16.112-14.305a119.567 119.567 0 0 1 69.571-21.836c17.92 0 48.34 1.958 69.196 17.92 20.856 15.963 60.988 53.233 61.665 96.452a156.612 156.612 0 0 1-46.682 112.264c-37.27 38.55-156.838 157.063-156.838 157.063s-23.868 28.085-35.84 26.278a23.04 23.04 0 0 1-15.058-8.81c-11.37-14.38 12.498-35.388 12.498-35.388l119.191-118.513-18.823-17.77-129.205 131.54s-15.059 11.52-27.859 3.162c-27.784-18.221-10.315-42.39-10.315-42.39l127.171-127.549-15.585-13.478 1.355 1.883zm-184.697 74.767a26.805 26.805 0 0 1-31.096-7.53c-14.306-15.811-3.84-28.762-3.84-28.762l103.153-103.906 37.647 37.045-105.864 103.153zm195.84-177.092c4.895 4.668 30.871 30.946 2.26 61.214a41.336 41.336 0 0 1-58.58 4.066l-104.207-104.81 2.862-4.14 36.065 36.442S71.53 259.816 64 266.969c-7.53 7.153-8.659 29.742-8.659 29.742a177.694 177.694 0 0 1-44.499-81.996C3.087 185.576 7.454 129.86 39.755 94.02S95.473 44.024 152.32 38.753a128.904 128.904 0 0 1 94.72 30.118s67.915 67.011 72.81 71.303z" />
    </g>
  ),
  height: 512,
  width: 512,
})
IconIconTypeCharity.displayName = 'IconIconTypeCharity'
export default IconIconTypeCharity
