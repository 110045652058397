import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconMessageCentre = createIconComponent({
  content: (
    <g>
      <path d="M34.438 342.997c-47.449-59.203-45.688-143.889 4.18-201.068A150.07 150.07 0 0 1 152.32 92.184h186.855c72.772-3.652 136.898 47.398 149.652 119.136a330.237 330.237 0 0 1 2.09 89.875c0 28.843-10.868 69.392 6.27 91.965 33.86 27.171 0 28.843-6.688 26.753a114.12 114.12 0 0 1-41.802-22.991l-23.41-21.32a58.105 58.105 0 0 0-17.556 4.181l17.557-6.688a250.813 250.813 0 0 1-58.105 17.975 698.932 698.932 0 0 1-158.012 9.196 326.893 326.893 0 0 1-140.456-25.5c-22.155-16.72-26.335-17.556-35.95-30.933" />
    </g>
  ),
  height: 512,
  width: 512,
})
IconMessageCentre.displayName = 'IconMessageCentre'
export default IconMessageCentre
