import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconIconFood = createIconComponent({
  content: (
    <g>
      <path d="M335.472 309.542h-23.136c-7.463 0-5.97-1.493-5.97-6.22V47.334a44.78 44.78 0 0 1 18.907-39.803 40.053 40.053 0 0 1 42.291-2.737 40.301 40.301 0 0 1 22.638 35.077v443.315a26.868 26.868 0 0 1-40.798 24.877 27.614 27.614 0 0 1-13.932-24.877V309.542zM175.76 124.454h15.672V12.506c0-7.712 4.23-12.438 8.956-12.438a8.707 8.707 0 0 1 9.702 7.463 34.764 34.764 0 0 1 0 6.468v110.455h15.673V12.257c.498-7.214 3.732-12.19 9.702-12.19a9.453 9.453 0 0 1 9.951 10.698v153.742c.072 18.254-12.906 33.953-30.848 37.316h-3.482v276.885c.37 3.556.37 7.14 0 10.697-2.385 13.417-14.519 22.867-28.112 21.892-12.734-.825-23.132-10.495-24.877-23.136a71.647 71.647 0 0 1 0-11.443V202.569h-5.97c-18.214-1.828-31.847-17.534-31.097-35.823V12.009c0-7.961 2.985-11.941 9.453-11.941s10.2 4.229 10.2 12.19v112.196h14.926V10.267a9.702 9.702 0 0 1 10.2-10.2 9.453 9.453 0 0 1 9.702 9.951v114.436h.249z" />
    </g>
  ),
  height: 512,
  width: 512,
})
IconIconFood.displayName = 'IconIconFood'
export default IconIconFood
