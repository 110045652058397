import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconMapIconBusiness = createIconComponent({
  content: (
    <g>
      <g fill="none">
        <path
          fill="#469944"
          d="M258.339 8.053c99.04 0 179.328 79.84 179.328 178.326 0 142.656-179.328 320.96-179.328 320.96S79 329.024 79 186.379C79 87.893 159.299 8.053 258.339 8.053"
        />
        <path
          fill="#FFF"
          d="M245.037 332.608c25.728.416 57.067-7.883 71.083-38.859 14.005-30.976 1.27-56.48-12.725-72.042-12.31-13.696-18.891-38.592-27.286-69.323-7.008-25.675-28.757-21.387-28.757-21.387"
        />
        <path
          fill="#FFF"
          d="M256.557 332.608c-25.728.416-57.066-7.883-71.082-38.859-14.006-30.976-1.27-56.48 12.725-72.042 12.31-13.696 18.89-38.592 27.285-69.323 7.008-25.675 28.758-21.387 28.758-21.387m-7.136-3.37s2.517-33.11 6.592-41.302c3.381-6.794 4.885-6.933 4.885-6.933s2.176-.95 4.107.16c1.941 1.11 2.485 1.653 1.109 4.427-1.376 2.762-4.97 7.744-6.357 17.706-1.376 9.952-2.07 25.718-2.07 25.718s-.074 1.685-1.76 1.738c-2.528.054-6.837.47-6.506-1.514z"
        />
        <path
          fill="#FFF"
          d="M265.507 121.323S252.643 86.059 311 88.832c.139-.01-12.992 54.613-45.493 32.49z"
        />
      </g>
    </g>
  ),
  height: 512,
  width: 512,
})
IconMapIconBusiness.displayName = 'IconMapIconBusiness'
export default IconMapIconBusiness
