import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconDonateFood = createIconComponent({
  content: (
    <g>
      <path
        stroke="#D0021B"
        d="M513 288.91H289v223.93h-64V288.91H1v-63.98h224V1h64v223.93h224z"
      />
    </g>
  ),
  height: 512,
  width: 512,
})
IconDonateFood.displayName = 'IconDonateFood'
export default IconDonateFood
