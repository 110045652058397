import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconIconTypeBusiness = createIconComponent({
  content: (
    <g>
      <path d="M246.633 98.511c-.006-.48-.006-1.013-.006-1.606v-.292c2.039-15.73 3.786-31.168 5.826-46.898.873-8.156 2.621-16.313 4.369-24.178A54.472 54.472 0 0 1 267.309 3.69 9.321 9.321 0 0 1 276.63.195c6.7 0 9.03 5.243 4.952 10.778a91.758 91.758 0 0 0-11.36 32.042c-1.748 11.36-2.33 26.217-3.787 33.79-1.45 7.537-1.457 17.957-1.457 22.365a51.85 51.85 0 0 1 36.703 30.36 299.742 299.742 0 0 1 11.07 35.538 635.023 635.023 0 0 0 27.09 81.562 154.095 154.095 0 0 0 26.8 40.49 140.113 140.113 0 0 1 34.663 68.746 123.8 123.8 0 0 1-9.03 71.95 130.209 130.209 0 0 1-92.923 76.902c-9.613 2.621-19.517 3.786-29.13 5.243L256.24 512a174.777 174.777 0 0 1-101.079-34.082 128.752 128.752 0 0 1-45.442-83.31 121.761 121.761 0 0 1 29.13-101.37 226.628 226.628 0 0 0 43.111-84.476c6.991-22.43 13.108-45.15 19.808-67.872a56.22 56.22 0 0 1 27.09-36.412 51.832 51.832 0 0 1 17.776-5.967zm32.036-17.045c-.63-20.999 12.293-40.029 32.043-47.19a110.11 110.11 0 0 1 39.907-8.739h29.13c3.495 0 3.495.583 3.495 3.787a145.648 145.648 0 0 1-35.538 64.959c-17.258 17.788-45.294 19.31-64.376 3.495a8.156 8.156 0 0 1-2.622-4.66c-2.039-4.37-2.039-9.03-2.039-11.652z" />
    </g>
  ),
  height: 512,
  width: 512,
})
IconIconTypeBusiness.displayName = 'IconIconTypeBusiness'
export default IconIconTypeBusiness
