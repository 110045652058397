export const GET_PROFILE_SETTINGS_LOADING =
  'settings/GET_PROFILE_SETTINGS_LOADING'
export const GET_PROFILE_SETTINGS_RECEIVED =
  'settings/GET_PROFILE_SETTINGS_RECEIVED'
export const GET_PROFILE_SETTINGS_REJECTED =
  'settings/GET_PROFILE_SETTINGS_REJECTED'

export const UPDATE_PROFILE_SETTINGS_LOADING =
  'settings/UPDATE_PROFILE_SETTINGS_LOADING'
export const UPDATE_PROFILE_SETTINGS_RECEIVED =
  'settings/UPDATE_PROFILE_SETTINGS_RECEIVED'
export const UPDATE_PROFILE_SETTINGS_REJECTED =
  'settings/UPDATE_PROFILE_SETTINGS_REJECTED'
