import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconMapIconVolunteer = createIconComponent({
  content: (
    <g>
      <g fill="none">
        <path
          fill="#9E5DAE"
          d="M433.672 185.707c0 142.154-178.784 319.84-178.784 319.84S76.104 327.86 76.104 185.707c0-98.134 80.043-177.686 178.784-177.686 98.73 0 178.784 79.552 178.784 177.686z"
        />
        <path
          fill="#FFF"
          d="M255.187 99.627c16.554 0 29.984 13.642 29.984 30.464 0 16.832-13.419 30.474-29.984 30.474-16.566 0-29.984-13.642-29.984-30.474 0-16.822 13.418-30.464 29.984-30.464zm46.986 170.368h-15.125v-57.888c0-2.454-.49-4.598-2.912-4.598-2.421 0-2.667 2.144-2.667 4.598v129.141H259.56v-71.253c0-2.454-1.963-4.448-4.384-4.448-2.421 0-4.384 1.994-4.384 4.448v71.253h-21.899V212.096c0-2.453-.629-4.597-3.05-4.597-2.422 0-2.528 2.144-2.528 4.597v57.888h-15.126v-62.347c0-24.597 21.995-43.477 46.198-43.477 24.202 0 47.786 18.88 47.786 43.477v62.358z"
        />
      </g>
    </g>
  ),
  height: 512,
  width: 512,
})
IconMapIconVolunteer.displayName = 'IconMapIconVolunteer'
export default IconMapIconVolunteer
