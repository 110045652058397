// @flow
import {fetch} from 'redux-simple-auth'
import {actionTypes} from 'redux-resource'

import {API_SERVER_URL} from '../../app-constants'
import {handleErrors} from '../../utils/handleErrors'

/**
 * Delete the profile with the given identifier
 * @param {*} profileId the identifier of the profile
 */
export const getProfileById = (profileId: number) => {
  return (dispatch: Dispatch) => {
    const generatedRequestKey = `fetchProfile-${profileId}`
    dispatch({
      type: actionTypes.READ_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'profiles',
      resources: [profileId],
    })

    dispatch(
      fetch(`${API_SERVER_URL}/v1/profiles/${profileId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch({
          type: actionTypes.READ_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          resourceType: 'profiles',
          resources: [json],
        })
        return json
      })
      .catch(error => {
        dispatch({
          type: actionTypes.READ_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'profiles',
          resources: [profileId],
          statusCode: error.statusCode,
          meta: {error: error.message},
        })
      })
  }
}

export default {getProfileById}
