import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconIconFacebook = createIconComponent({
  content: (
    <g>
      <path
        fill="#003479"
        fillRule="evenodd"
        d="M216.557 92.043c-12.129 16.331-12.568 42.71-12.568 59.78v38.796H165v57.705h38.989v200.553h73.075V248.324H334.9l4.33-57.705h-62.166V145.54c0-15.518 13.577-21.06 28.738-21.06 15.165 0 31.41 4.803 31.41 4.803l9.75-59.118S326.234 63 277.064 63c-30.182 0-47.724 11.749-60.507 29.043"
      />
    </g>
  ),
  height: 512,
  width: 512,
})
IconIconFacebook.displayName = 'IconIconFacebook'
export default IconIconFacebook
