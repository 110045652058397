// @flow
import React, {Component} from 'react'

import {IconBusiness} from '../icons'
//import './LoadingIndicator.scss'

type Props = {
  message: string,
}

type State = {
  enableMessage: boolean,
}

/**
 * LoadingIndicator
 */
class LoadingIndicator extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.enableMessage = this.enableMessage.bind(this)

    this.state = {
      displayMessage: false,
    }

    this.timer = setTimeout(this.enableMessage, 250)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  enableMessage() {
    this.setState({displayMessage: true})
  }

  render() {
    const {displayMessage = false} = this.state
    const {message} = this.props

    if (!displayMessage) {
      return null
    }

    return (
      <div className="loading_indicator__container">
        <div className="loading_indicator__wrapper">
          <div className="loading_indicator__indicator" />
          <div className="loading_indicator__icon">
            <IconBusiness />
          </div>
        </div>
        <div className="loading_indicator__message">
          {message || 'Loading...'}
        </div>
      </div>
    )
  }
}

export default LoadingIndicator
