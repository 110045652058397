// @flow

/**
 * Returns an array of resources from resources based on the filter provided.
 */
export default function(entityState, filter) {
  if (!entityState) {
    return []
  }

  const entities = entityState.resources

  let filterIdentifiers = []
  if (typeof filter === 'function' || !filter) {
    const resourceFilter = filter ? filter : () => true
    return Object.values(entities).filter(entity => {
      return resourceFilter(entity, entityState.meta[entity.id], entityState)
    })
  } else if (typeof filter === 'string') {
    const list = entityState.lists[filter]
    if (!list) {
      return []
    }

    filterIdentifiers = list
  } else {
    filterIdentifiers = filter
  }

  // Avoid calling the mapper when the identifiers list is empty
  if (!filterIdentifiers.length) {
    return []
  }

  return filterIdentifiers.map(id => entities[id]).filter(Boolean)
}
