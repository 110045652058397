import {setResourceMeta} from 'redux-resource'

/**
 * Update the metadata of all the resources included in a successful request
 * @param {*} resourceType  the resource type to apply the reducer to
 * @param {*} options the options of the reducer
 */
export const updateMergeMetadataResourceReducer = (
  resourceType,
  options = {},
) => {
  return (state, action) => {
    const {initialResourceMeta} = options
    const isValidAction = [
      'READ_RESOURCES_SUCCEEDED',
      'UPDATE_RESOURCES_SUCCEEDED',
      'DELETE_RESOURCES_SUCCEEDED',
    ].includes(action.type)
    if (!isValidAction) {
      return state
    }
    if (action.resourceType !== resourceType) {
      return state
    }

    if (!action.meta) {
      return state
    }

    const newMeta = action.meta || {}

    const meta = setResourceMeta({
      resources: action.resources,
      meta: state.meta,
      newMeta,
      initialResourceMeta,
    })

    return {
      ...state,
      meta,
    }
  }
}

/**
 * Update the pagination for the given resource type
 * @param {*} resourceType  the resource type to apply the reducer to
 * @param {*} options the options of the reducer
 */
export const paginationResourceReducer = resourceType => {
  return (state, action) => {
    const isValidAction = [
      'READ_RESOURCES_SUCCEEDED',
      'UPDATE_RESOURCES_SUCCEEDED',
      'DELETE_RESOURCES_SUCCEEDED',
    ].includes(action.type)
    if (!isValidAction) {
      return state
    }
    if (action.resourceType !== resourceType) {
      return state
    }

    if (!action.pagination) {
      return state
    }

    const {requestKey} = action
    const {
      mergePages: shouldMergePages = false,
      lastRequestedPage,
      pages: availablePages = [],
      ...ignoredNewActionInfo
    } = action.pagination
    let newAvailablePages = state.pagination[requestKey]
      ? state.pagination[requestKey].availablePages
      : []
    if (shouldMergePages) {
      newAvailablePages = [...newAvailablePages, lastRequestedPage]
    } else {
      newAvailablePages = availablePages
    }

    const newState = {
      ...state,
      lists: {
        ...state.lists,
        current: (state.lists && (state.lists[action.list] || [])) || [],
      },
      pagination: {
        ...state.pagination,
        [requestKey]: {
          ...(typeof action.pagination[requestKey] === 'object' &&
            action.pagination[requestKey]),
          ...(action.pagination[requestKey] || {}),
          availablePages: newAvailablePages,
          lastFetchedAt: {
            ...state.pagination.lastFetchedAt,
            [lastRequestedPage]: new Date(),
          },
        },
      },
    }
    return newState
  }
}

export default {paginationResourceReducer, updateMergeMetadataResourceReducer}
