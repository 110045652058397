import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconIconCommunityMap = createIconComponent({
  content: (
    <g>
      <g fillRule="evenodd">
        <path d="M224.373 146.848c0 41.739-33.837 75.575-75.577 75.575-41.739 0-75.575-33.836-75.575-75.575 0-41.74 33.836-75.577 75.575-75.577 41.74 0 75.577 33.837 75.577 75.577M11.014 327.796c16.212-56.227 51.194-84.939 102.284-85.35 58.975-.473 122.155-15.284 164.733 52.818 6.357 10.168 9.023 17.195 11.793 32.53 5.477 24.808 6.822 39.022 7.159 48.835-33.62 0-31.55-.175-60.789-.002l.273-.171-172.137-.002.27.175c-49.83-.256-44.066-.67-64.6-.67.94-10.645 1.32-13.002 11.014-48.163M426.43 174.93c0 48.459-39.283 87.742-87.743 87.742-48.457 0-87.74-39.283-87.74-87.742 0-48.458 39.283-87.74 87.74-87.74 48.46 0 87.743 39.282 87.743 87.74" />
        <path d="M178.726 385.005c18.823-65.277 59.435-98.61 118.75-99.088 68.468-.548 141.817-17.745 191.25 61.32 7.379 11.804 10.474 19.963 13.69 37.766 6.36 28.8 7.92 45.303 8.312 56.696-39.032 0-36.63-.204-70.574-.002l.318-.2-199.849-.002.314.204c-57.851-.298-51.158-.777-74.997-.777 1.092-12.359 1.532-15.097 12.786-55.917" />
      </g>
    </g>
  ),
  height: 512,
  width: 512,
})
IconIconCommunityMap.displayName = 'IconIconCommunityMap'
export default IconIconCommunityMap
