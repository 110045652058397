import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconIconTwitter = createIconComponent({
  content: (
    <g>
      <path
        fill="#00B2DF"
        fillRule="evenodd"
        d="M512 97.304c-18.839 8.363-39.081 14.013-60.33 16.556 21.686-13.013 38.34-33.62 46.184-58.172a210.111 210.111 0 0 1-66.705 25.515C411.99 60.768 384.69 48 354.48 48c-58.01 0-105.047 47.076-105.047 105.142 0 8.24.929 16.263 2.72 23.96-87.3-4.386-164.7-46.244-216.51-109.856-9.04 15.528-14.22 33.588-14.22 52.859 0 36.479 18.544 68.661 46.73 87.516a104.6 104.6 0 0 1-47.58-13.152c-.008.44-.008.88-.008 1.322 0 50.942 36.209 93.438 84.262 103.099a105.06 105.06 0 0 1-27.673 3.69c-6.77 0-13.352-.664-19.764-1.887 13.367 41.77 52.16 72.17 98.125 73.016-35.951 28.202-81.243 45.009-130.46 45.009A213.21 213.21 0 0 1 0 417.248c46.486 29.834 101.7 47.242 161.02 47.242 193.211 0 298.87-160.216 298.87-299.158 0-4.558-.102-9.091-.306-13.602 20.528-14.823 38.334-33.341 52.416-54.426"
      />
    </g>
  ),
  height: 512,
  width: 512,
})
IconIconTwitter.displayName = 'IconIconTwitter'
export default IconIconTwitter
