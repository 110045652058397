/* eslint-disable */
import React from 'react'

const createIconComponent = ({content, height, width}) => props =>
  React.createElement(
    'svg',
    {
      ...props,
      style: {...styles.root, ...props.style},
      viewBox: `0 0 ${width} ${height}`,
    },
    content,
  )
const styles = {
  root: {
    display: 'inline-block',
    fill: 'currentcolor',
    height: '1.25em',
    maxWidth: '100%',
    position: 'relative',
    userSelect: 'none',
    textAlignVertical: 'text-bottom',
  },
}
export default createIconComponent
