export const API_SERVER_URL =
  window.env.REACT_APP_GATEWAY_SERVER_URL || process.env.REACT_APP_GATEWAY_SERVER_URL || ''
export const FRONTEND_URL =
  window.env.REACT_APP_FRONTEND_URL || process.env.REACT_APP_FRONTEND_URL || ''
export const DASHBOARD_URL =
  window.env.REACT_APP_DASHBOARD_URL || process.env.REACT_APP_DASHBOARD_URL || ''

export default {
  API_SERVER_URL,
  FRONTEND_URL,
  DASHBOARD_URL,
}
