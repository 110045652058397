// @flow
import {createSelector} from 'reselect'

/**
 * Returns all the identifiers associated with the given resource name
 */
export const getResourceIds = (resourceName: string, listName: string) => (
  state: State,
) => state.resources[resourceName].lists[listName]

/**
 * Returns all the entities for the given resource name
 */
export const getResourceMap = (resourceName: string) => (state: State) =>
  state.resources[resourceName].entities

/**
 * Returns the value of the metadata key of the given resource
 */
export const getResourceMeta = (resourceName: string, metaKey: string) => (
  state: State,
) => state.resources[resourceName].meta[metaKey]

const resourceSelectors = {}

/**
 * Returns a selection of items to give up the world beyong awesomeness
 */
export const getResourceMappedList = (
  resourceName: string,
  listName: string,
) => {
  if (resourceSelectors[`${resourceName}${listName}`]) {
    return resourceSelectors[`${resourceName}${listName}`]
  }
  resourceSelectors[`${resourceName}${listName}`] = createSelector(
    [getResourceIds(resourceName, listName), getResourceMap(resourceName)],
    (ids = [], map) => ids.map(id => map[id]),
  )

  return resourceSelectors[`${resourceName}${listName}`]
}

/**
 * Returns the resource item with the given identifier
 */
export const getResourceItemById = (resourceName: string, id: Id) => (
  state: State,
) => state.resources[resourceName].entities[id] || null

export default {
  getResourceIds,
  getResourceMap,
  getResourceMeta,
  getResourceItemById,
  getResourceMappedList,
}
