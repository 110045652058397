// @flow
import {getStatus} from 'redux-resource'

/**
 * Returns the donation with the given identifier
 */
export const getDonationById = (state, id: Id) => {
  return state.resources.donations.resources[id]
}

/**
 * Returns whether there are any inflight requests for donations
 */
export const isLoading = _state => {
  return false
}

/**
 * Returns the fetch status of a inflight request
 */
export const getFetchStatusById = (state: State, id: Id) => {
  return getStatus(state.resources.donations, `requests[${id}].status`, true)
}

/**
 * Returns whether the donation with the given identifier is loaded
 */
export const isDonationLoaded = (id, state) => {
  const fetchStatus = getFetchStatusById(state, id)
  return fetchStatus.succeeded
}

/**
 * Returns whether the donation with the given identifier is loaded
 */
export const isDonationLoading = (id, state) => {
  const fetchStatus = getFetchStatusById(state, id, true)
  return fetchStatus.pending
}

export default {
  getDonationById,
  getFetchStatusById,
  isLoading,
  isDonationLoading,
  isDonationLoaded,
}
