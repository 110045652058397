import {NAME} from './constants'

const getData = state => {
  return state[NAME].data
}

export const getSessionData = state => {
  return {
    profile: getData(state),
  }
}

export const getProfileSessionData = state => {
  return getData(state)
}

export const isSessionLoaded = state => {
  return state[NAME].isReady || false
}

export default {
  getSessionData,
  getProfileSessionData,
}
