import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconImpactReport = createIconComponent({
  content: (
    <g>
      <path d="M511.121 199.97l-81.306 59.099-63.756 46.205c-7.042 3.797-10.122 12.267-7.164 19.7l24.356 75.217 27.222 83.814c0 3.224 2.15 6.09 2.865 9.313.717 3.224 0 3.582 0 4.656 0 1.075-3.581 0-5.014 0l-14.327-10.745-59.458-44.414-69.487-50.145a15.76 15.76 0 0 0-18.625 0l-67.338 47.637-57.666 42.982-18.267 13.253s-3.224 2.507-5.015 0c-1.79-2.508 0-3.224 0-5.015l16.834-51.936 35.818-107.454a50.503 50.503 0 0 0 3.94-15.043 14.685 14.685 0 0 0-6.805-12.536l-66.263-44.773-77.367-56.592L0 199.969v-2.507c2.709-.64 5.53-.64 8.238 0H179.09c8.485 1.296 16.447-4.436 17.91-12.894l26.146-80.59 27.58-85.247c0-2.15 2.15-5.731 5.015-5.731 2.865 0 3.223 3.582 3.94 5.73l35.818 115.334 16.476 50.146a15.402 15.402 0 0 0 16.476 11.82h173a17.909 17.909 0 0 1 7.88 0l1.791 3.94z" />
    </g>
  ),
  height: 512,
  width: 512,
})
IconImpactReport.displayName = 'IconImpactReport'
export default IconImpactReport
