// @flow
/* eslint-disable react/no-unsafe,camelcase */
import React, {Children, Component} from 'react'
import {connect} from 'react-redux'
import warning from 'warning'
import isArray from 'lodash/isArray'
import {getProfileSessionData} from '../reducers/session/selectors'

const isEmptyChildren = children => Children.count(children) === 0

type RenderProps = {
  profileType: string,
}

type ProfileType = 'business' | 'charity' | 'transporter' | 'volunteer'

type Props = {
  children?: React.Node,
  render?: (props: RenderProps) => void,
  type?: ProfileType & 'all',
  profileType?: ProfileType,
  $profileType?: ProfileType,
}

type State = {
  canShow: boolean,
}

/**
 * @private Returns the profile type from the authenticated user session data
 */
export const getProfileType = (state: any) => {
  const sessionData = getProfileSessionData(state)
  return sessionData && sessionData.profileType
}

export class WithProfileType extends Component<Props, State> {
  static defaultProps = {
    type: 'all',
    $profileType: undefined,
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      canShow: false,
    }
  }

  componentDidMount() {
    this.checkProfileType(this.props)
  }

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    warning(
      !(
        this.props.render &&
        this.props.children &&
        !isEmptyChildren(this.props.children)
      ),
      'You should not use both <withProfileType render> and <withProfileType children>; <withProfileType children> will be ignored',
    )
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.profileType !== prevProps.profileType ||
      this.props.$profileType !== prevProps.$profileType
    ) {
      this.checkProfileType(this.props)
    }
  }

  checkProfileType(props: Props) {
    const {profileType, $profileType, type} = props
    if (type === 'all') {
      this.setState({canShow: true})
      return
    }

    const expectedType = isArray(type) ? type : [type]
    const actualProfileType = $profileType || profileType
    this.setState({canShow: expectedType.indexOf(actualProfileType) !== -1})
  }

  render() {
    const {render, children, profileType, ...props} = this.props

    const {canShow = false} = this.state
    if (!canShow) {
      return null
    }

    if (render) {
      return render({profileType, ...props})
    }

    return children ? children : null
  }
}

const mapStateToProps = state => ({
  profileType: getProfileType(state),
})

export default connect(mapStateToProps)(WithProfileType)
