import * as actionTypes from './actionTypes'
import {
  COLLECTIONTIMES,
  DONATIONFOODTYPES,
  DONATIONSIZES,
  FOODTYPES,
  TRANSPORTOPTIONS,
  VOLUNTEERROLES,
  COUNTRIES,
} from './constants'

const initialState = {
  ready: false,
  loading: false,
  transportOptionsReady: false,
  transportOptionsLoading: false,
  donationSizesReady: false,
  donationSizesLoading: false,
  donationFoodTypesReady: false,
  donationFoodTypesLoading: false,
  collectionTimesReady: false,
  collectionTimesLoading: false,
  foodTypesReady: false,
  foodTypesLoading: false,
  volunteerRolesReady: false,
  volunteerRolesLoading: false,
  countriesReady: false,
  countriesLoading: false,
  [DONATIONFOODTYPES]: {},
  [DONATIONSIZES]: {},
  [TRANSPORTOPTIONS]: {},
  [COLLECTIONTIMES]: {},
  [FOODTYPES]: {},
  [VOLUNTEERROLES]: {},
  [COUNTRIES]: {},
}

export default function lookupReducer(state = initialState, action) {
  const payload = action.payload
  switch (action.type) {
    case actionTypes.LOOKUP_LOADING:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOOKUP_RECEIVED:
      return {
        ...state,
        loading: false,
        ready: true,
      }
    case actionTypes.LOOKUP_DONATIONSIZES:
      return {
        ...state,
        donationSizesReady: true,
        donationSizesLoading: true,
        [DONATIONSIZES]: payload,
      }
    case actionTypes.LOOKUP_DONATIONFOODTYPES:
      return {
        ...state,
        donationFoodTypesReady: true,
        donationFoodTypesLoading: true,
        [DONATIONFOODTYPES]: payload,
      }
    case actionTypes.LOOKUP_FOODTYPES:
      return {
        ...state,
        foodTypesReady: true,
        foodTypesLoading: true,
        [FOODTYPES]: payload,
      }
    case actionTypes.LOOKUP_COLLECTIONTIMES:
      return {
        ...state,
        collectionTimesReady: true,
        collectionTimesLoading: true,
        [COLLECTIONTIMES]: payload,
      }
    case actionTypes.LOOKUP_TRANSPORTOPTIONS:
      return {
        ...state,
        transportOptionsReady: true,
        transportOptionsLoading: true,
        [TRANSPORTOPTIONS]: payload,
      }
    case actionTypes.LOOKUP_VOLUNTEERROLES:
      return {
        ...state,
        volunteerRolesReady: true,
        volunteerRolesLoading: true,
        [VOLUNTEERROLES]: payload,
      }
    case actionTypes.LOOKUP_COUNTRIES:
      return {
        ...state,
        countriesReady: true,
        countriesLoading: true,
        [COUNTRIES]: payload,
      }
    default:
      return state
  }
}
