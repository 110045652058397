import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconMapIconTransport = createIconComponent({
  content: (
    <g>
      <g fill="none">
        <path
          fill="#E15AA4"
          d="M254.088 7.701c98.741 0 178.795 79.595 178.795 177.782 0 142.229-178.795 320.01-178.795 320.01S75.293 327.712 75.293 185.483C75.293 87.296 155.336 7.7 254.088 7.7"
        />
        <path
          fill="#FFF"
          d="M183.08 154.219l13.558-42.432s6.474-9.43 8.245-10.016c1.77-.587 91.328-1.174 91.328-1.174l14.73 8.246 12.96 36.533 4.129 14.73 7.658-2.357 14.731 1.174 6.485 5.301-2.357 7.072-8.843 3.541.587 15.318 5.301 39.488-1.173 40.661-7.659 10.603-22.389-4.715-5.888-12.96 1.77-12.373-10.015-2.944-116.086 2.357 3.542 20.63-11.2 10.015-20.032-4.714-4.715-18.859 2.357-44.779 4.715-38.304-13.557-6.485 2.357-8.843h24.171l3.541-4.128"
        />
        <path
          fill="#E15AA4"
          d="M351.144 152.843h-19.061c-.757 0-1.44.245-2.059.597l-8.053-32.01c-2.165-11.969-10.656-24.033-24.736-24.033H212.35c-14.006 0-22.518 12.064-24.672 23.755l-8.139 32.395c-.65-.406-1.376-.694-2.208-.694h-19.072c-5.312 0-9.632 4.32-9.632 9.632v4.598c0 5.173 4.096 9.408 9.216 9.621l1.579.267a37.767 37.767 0 0 0-1.835 11.754l-2.688 30.923v49.504c0 7.605 6.187 13.792 13.792 13.792h14.453c7.606 0 13.792-6.187 13.792-13.792v-15.819h115.659v15.808c0 7.606 6.197 13.792 13.792 13.792h14.453c7.606 0 13.792-6.186 13.792-13.792v-20.16l-.01-29.706-2.656-30.166c0-4.277-.63-8.352-1.856-12.16l1.418-.245c5.12-.224 9.216-4.448 9.216-9.621v-4.598c.032-5.322-4.298-9.642-9.6-9.642zm-155.029-29.867c1.568-8.437 7.19-16.95 16.235-16.95h84.885c9.728 0 14.816 9.12 16.288 17.217l9.205 36.629c-.362 8.853-10.346 15.659-18.954 15.659H205.81c-8.608 0-18.603-6.806-18.955-15.659l9.259-36.896zm-7.797 146.165c0 2.838-2.315 5.142-5.152 5.142h-14.454c-2.837 0-5.152-2.304-5.152-5.142v-15.808h24.768v15.808h-.01zm152.554 5.152H326.42c-2.837 0-5.152-2.304-5.152-5.141v-15.819h24.757v15.808a5.155 5.155 0 0 1-5.152 5.152zm11.254-107.221c0 .8-.939.917-1.718 1.056l-7.061 1.205a4.325 4.325 0 0 0-3.136 2.326 4.305 4.305 0 0 0 .021 3.904c2.091 4.085 3.115 8.512 3.126 13.92l2.656 30.165v25.045H163.539v-24.661l2.688-30.912c0-5.013 1.013-9.43 3.104-13.525.63-1.227.63-2.667.01-3.904a4.345 4.345 0 0 0-3.135-2.326l-7.211-1.237c-.768-.128-1.707-.256-1.717-1.056v-4.597c0-.544.448-.992.981-.992h19.061c.352 0 .662-.118.992-.203 1.238 13.216 15.051 22.89 27.488 22.89h97.963c12.437 0 26.23-9.653 27.488-22.858.277.053.533.17.821.17h19.062c.544 0 .981.449.981.993v4.597h.01z"
        />
        <path
          fill="#E15AA4"
          d="M278.344 204.224H231.23a4.322 4.322 0 0 0-4.32 4.32c0 2.39 1.941 4.32 4.32 4.32h47.114c2.39 0 4.32-1.93 4.32-4.32 0-2.379-1.93-4.32-4.32-4.32zm0 18.581H231.23a4.322 4.322 0 0 0-4.32 4.32c0 2.39 1.941 4.32 4.32 4.32h47.114c2.39 0 4.32-1.93 4.32-4.32 0-2.378-1.93-4.32-4.32-4.32zm-78.965-24.224c-10.293 0-18.667 8.374-18.667 18.667 0 10.293 8.374 18.667 18.667 18.667 10.293 0 18.667-8.374 18.667-18.667 0-10.293-8.374-18.667-18.667-18.667zm0 28.694c-5.536 0-10.027-4.491-10.027-10.027 0-5.525 4.491-10.016 10.027-10.016 5.525 0 10.016 4.49 10.016 10.016.01 5.525-4.49 10.027-10.016 10.027zm110.816-28.694c-10.293 0-18.667 8.374-18.667 18.667 0 10.293 8.374 18.667 18.667 18.667 10.293 0 18.667-8.374 18.667-18.667 0-10.293-8.374-18.667-18.667-18.667zm0 28.694c-5.536 0-10.027-4.491-10.027-10.027 0-5.525 4.491-10.016 10.027-10.016 5.525 0 10.016 4.49 10.016 10.016.01 5.525-4.49 10.027-10.016 10.027zm-68.448-103.339h20.032a3.712 3.712 0 0 0 3.701-3.712 3.703 3.703 0 0 0-3.701-3.701h-20.032a3.696 3.696 0 0 0-3.702 3.701 3.706 3.706 0 0 0 3.702 3.712z"
        />
      </g>
    </g>
  ),
  height: 512,
  width: 512,
})
IconMapIconTransport.displayName = 'IconMapIconTransport'
export default IconMapIconTransport
