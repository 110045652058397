import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconIconInstagram = createIconComponent({
  content: (
    <g>
      <g fill="#D40062" fillRule="evenodd">
        <path d="M466.698 469c-15.192 15.079-33.3 25.172-53.601 31.644-18.113 5.773-36.786 7.746-55.636 8.578-50.716 2.238-101.464 1.475-152.201 1.397-21.645-.031-43.308-.439-64.904-2.112-21.175-1.64-41.817-5.706-61.114-15-9.91-4.772-18.915-11.045-27.265-18.141-10.376-8.817-19.037-19.223-26.649-30.5-3.364-4.985-5.38-10.652-8.096-15.956-5.02-9.803-7.086-20.461-9.973-30.926-3.572-12.953-3.483-26.232-4.448-39.413-1.74-23.744-.817-47.562-1.547-71.337.223-37.26.425-74.519.683-111.776.066-9.525.136-19.108.846-28.552 2.073-27.594 7.297-54.53 22.171-78.552 18.178-29.358 44.133-48.529 77.265-58.057 14.52-4.174 29.357-6.217 44.44-6.988 64.547-3.304 129.125-1.923 193.69-1.242 21.426.227 42.897 1.386 63.846 6.715 18.421 4.685 35.601 12.085 50.717 23.815 27.875 21.631 44.02 50.338 50.378 84.695 4.518 24.401 4.732 49.165 4.992 73.879.515 49.378.795 98.762-.39 148.132-.558 23.22-1.394 46.494-7.863 69.087-6.536 22.822-17.192 43.236-34.679 59.69-.27.254-.444.61-.662.92zm-26.895-38.39c9.367-8.687 13.853-20.143 17.645-31.86 6.15-19.013 6.27-38.826 6.936-58.514 1.407-41.62.737-83.259.688-124.89-.035-28.602.514-57.307-3.878-85.66-6.036-38.97-27.1-65.503-66.047-76.23-18.453-5.084-37.44-5.333-56.355-5.932-40.461-1.28-80.939-.746-121.41-.657-28.858.063-57.772-.263-86.45 3.704-17.733 2.452-34.228 8.295-48.352 19.846-21.147 17.293-29.943 40.61-32.888 66.82-1.829 16.285-2.121 32.653-2.457 49.01-.622 30.284-.544 60.57-.213 90.86-.477 14.554-.1 29.106.17 43.66.368 19.957.415 39.939 3.275 59.753 2.344 16.252 7.208 31.642 17.006 45.058 15.607 21.377 37.47 31.897 63.05 35.751 20.576 3.1 41.352 3.323 62.064 3.463 55.655.38 111.325 1.387 166.957-1.202 22.149-1.03 43.764-4.476 62.853-16.933 6.671-4.355 12.963-9.226 17.406-16.048z" />
        <path d="M363.637 330.26c-11.177 15.54-24.808 28.473-41.312 38.21-14.146 8.343-29.308 14.654-45.556 16.81-65.822 8.735-119.75-26.838-141.786-80.097-4.651-11.243-7.363-22.979-8.953-35.004-1.457-14.327-.862-28.677 2.17-42.646 2.327-10.71 6.29-21.106 11.308-30.995 7.295-14.377 17.274-26.76 28.855-37.773 11.02-10.48 24.163-17.824 38.11-23.82 11.031-4.742 22.654-7.441 34.378-9.05 8.197-1.124 16.708-.96 25.063-.208 16.353 1.472 32.115 5.305 46.881 12.62 24.41 12.094 43.646 29.805 57.07 53.582 13.35 23.644 18.496 49.113 16.25 76.11-.743 8.946-2.18 17.93-4.91 26.354-3.713 11.447-8.66 22.563-15.53 32.634-.723 1.06-1.36 2.18-2.038 3.272zm-38.388-24.984c5.938-7.423 9.637-16.027 12.257-24.998 3.063-10.492 4.717-21.084 2.739-32.268-1.345-7.605-1.759-15.365-4.76-22.596-11.697-28.177-32.343-45.549-62.244-52.217-12.574-2.804-25.073-2.797-37.334.172-44.377 10.746-71.555 53.191-63.59 98.564 1.086 5.413 2.32 10.827 4.51 15.88 15.734 36.327 49.416 56.657 88.842 52.658 24.96-2.532 44.875-14.735 59.58-35.195zM391.682 89.9c17.02.042 30.62 13.403 30.312 30.388-.355 19.53-13.6 29.922-30.459 31.049-15.927 1.067-31.292-15.374-30.598-31.97.681-16.264 13.972-29.698 30.745-29.467" />
      </g>
    </g>
  ),
  height: 512,
  width: 512,
})
IconIconInstagram.displayName = 'IconIconInstagram'
export default IconIconInstagram
