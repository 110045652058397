import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconCommunity = createIconComponent({
  content: (
    <g>
      <path d="M386.634 192.65c0-71.324-58.046-129.145-129.65-129.145-71.603 0-129.65 57.82-129.65 129.146 0 71.325 58.047 129.145 129.65 129.145 71.604 0 129.65-57.82 129.65-129.145m-129.65 318.99c-19.88-21.956-39.327-43.05-57.91-65.004a839.464 839.464 0 0 1-94.212-132.59 300.625 300.625 0 0 1-37.599-98.58c-7.752-47.682 4.601-96.437 34.141-134.743 58.837-83.417 173.311-105.653 259.3-50.366 72.675 45.294 105.382 133.827 79.52 215.242a504.398 504.398 0 0 1-79.087 142.06c-31.116 40.036-64.825 77.918-97.67 116.662l-6.915 6.457" />
    </g>
  ),
  height: 512,
  width: 512,
})
IconCommunity.displayName = 'IconCommunity'
export default IconCommunity
