// @flow
import {fetch} from 'redux-simple-auth'
import parseLinkHeader from 'parse-link-header'
import {actionTypes} from 'redux-resource'
import {handleErrors} from '../../utils/handleErrors'
import {API_SERVER_URL} from '../../app-constants'

/**
 *
 */
export const getPaginationDataFromHeaders = function getPaginationDataFromHeaders(
  headers: Headers,
) {
  const linkHeader = headers.get('Link')
  const itemsCountHeader = headers.get('X-Items-Count')
  return {
    links: parseLinkHeader(linkHeader),
    totalItems: parseInt(itemsCountHeader, 10),
  }
}

/**
 * Returns the donation with the given identifier
 * @param {*} donationId the identifier of the food donation
 */
export const getDonations = (filter: string, page: String = '') => {
  return (dispatch: Dispatch, _getState: GetState) => {
    const generatedRequestKey = `donations:${page}`
    dispatch({
      type: actionTypes.READ_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'donations',
      resources: [],
    })

    dispatch(
      fetch(`${API_SERVER_URL}/v1/donations/available`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch({
          type: actionTypes.READ_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          resourceType: 'donations',
          resources: [json],
        })
        return json
      })
      .catch(error => {
        dispatch({
          type: actionTypes.READ_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'donations',
          resources: [],
          statusCode: error.statusCode,
          meta: {error: error.message},
        })
      })
  }
}

/**
 * Returns the donation with the given identifier
 * @param {*} donationId the identifier of the food donation
 */
export const getDonationById = (donationId: number) => {
  return (dispatch: Dispatch, _getState: GetState) => {
    const generatedRequestKey = `fetchDonation:${donationId}`
    dispatch({
      type: actionTypes.READ_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'donations',
      resources: [donationId],
    })

    dispatch(
      fetch(`${API_SERVER_URL}/v1/donations/${donationId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch({
          type: actionTypes.READ_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          resourceType: 'donations',
          resources: [json],
        })
        return json
      })
      .catch(error => {
        dispatch({
          type: actionTypes.READ_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'donations',
          resources: [donationId],
          statusCode: error.statusCode,
          meta: {error: error.message},
        })
      })
  }
}

/**
 * Claim the given donation on behalf of the given profile
 * @param {*} donationId the identifier of the food donation
 */
export const claimDonationById = (donationId: number) => {
  return (dispatch: Dispatch, _getState: GetState) => {
    const generatedRequestKey = `claimDonation:${donationId}`
    dispatch({
      type: actionTypes.UPDATE_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'donations',
      resources: [donationId],
    })

    dispatch(
      fetch(`${API_SERVER_URL}/v1/donations/${donationId}/claim`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch({
          type: actionTypes.UPDATE_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          resourceType: 'donations',
          resources: [json],
        })
        return json
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPDATE_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'donations',
          resources: [donationId],
          statusCode: error.statusCode,
          meta: {error: error.message},
        })
      })
  }
}

export const resetRequestState = (
  id: number,
  requestKey: string,
  resourceType: string,
) => {
  return (dispatch: Dispatch, _getState: GetState) => {
    dispatch({
      type: actionTypes.READ_RESOURCES_NULL,
      requestKey,
      resourceType,
      resources: [id],
    })
  }
}

/**
 * Claim the given donation on behalf of the given profile
 * @param {*} donationId the identifier of the food donation
 */
export const updateDonationById = (donationId: number, data: object) => {
  return (dispatch: Dispatch, _getState: GetState) => {
    return new Promise((resolve, reject) => {
      const generatedRequestKey = `updateDonation:${donationId}`
      dispatch({
        type: actionTypes.UPDATE_RESOURCES_PENDING,
        requestKey: generatedRequestKey,
        resourceType: 'donations',
        resources: [donationId],
      })

      dispatch(
        fetch(`${API_SERVER_URL}/v1/donations/${donationId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }),
      )
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
          dispatch({
            type: actionTypes.UPDATE_RESOURCES_SUCCEEDED,
            requestKey: generatedRequestKey,
            resourceType: 'donations',
            resources: [json],
          })
          return resolve(json)
        })
        .catch(error => {
          dispatch({
            type: actionTypes.UPDATE_RESOURCES_FAILED,
            requestKey: generatedRequestKey,
            resourceType: 'donations',
            resources: [donationId],
            statusCode: error.statusCode,
            meta: {error: error.message},
          })
          return reject(error)
        })
    })
  }
}

/**
 * Claim the given donation on behalf of the given profile
 * @param {*} donationId the identifier of the food donation
 */
export const deleteDonationById = (donationId: number) => {
  return (dispatch: Dispatch, _getState: GetState) => {
    const generatedRequestKey = `deleteDonation:${donationId}`
    dispatch({
      type: actionTypes.DELETE_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'donations',
      resources: [donationId],
    })

    dispatch(
      fetch(`${API_SERVER_URL}/v1/donations/${donationId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch({
          type: actionTypes.DELETE_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          resourceType: 'donations',
          resources: [json],
        })
        return json
      })
      .catch(error => {
        dispatch({
          type: actionTypes.DELETE_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'donations',
          resources: [donationId],
          statusCode: error.statusCode,
          meta: {error: error.message},
        })
      })
  }
}

/**
 * Claim the given donation on behalf of the given profile
 * @param {*} donationId the identifier of the food donation
 */
export const uploadDonationImage = (selectedImage: File) => {
  return (dispatch: Dispatch, _getState: GetState) => {
    return new Promise((resolve, reject) => {
      const selectedImageName = `${selectedImage.name}_${selectedImage.size}`
      const generatedRequestKey = `donationImage:${selectedImageName}`
      dispatch({
        type: actionTypes.CREATE_RESOURCES_PENDING,
        requestKey: generatedRequestKey,
        resourceType: 'donations',
        resources: [],
      })

      // Prepare the form data for the upload request
      const formData = new FormData()
      formData.append('image', selectedImage)

      dispatch(
        fetch(`${API_SERVER_URL}/v1/images`, {
          method: 'POST',
          body: formData,
        }),
      )
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
          dispatch({
            type: actionTypes.CREATE_RESOURCES_SUCCEEDED,
            requestKey: generatedRequestKey,
            resourceType: 'donations',
            resources: [],
          })
          return resolve(json)
        })
        .catch(error => {
          dispatch({
            type: actionTypes.CREATE_RESOURCES_FAILED,
            requestKey: generatedRequestKey,
            resourceType: 'donations',
            resources: [],
            statusCode: error.statusCode,
            meta: {error: error.message},
          })
          return reject(error)
        })
    })
  }
}
