import React from 'react'
import createIconComponent from './utils/createIconComponent'

const IconIconSettings = createIconComponent({
  content: (
    <g>
      <path d="M81.037 167.348h-2.339v-132.9C78.698 25.916 71.782 19 63.25 19S47.8 25.916 47.8 34.448v132.9h-4.656A43.189 43.189 0 0 0 0 210.58v62.72c.024 23.818 19.327 43.12 43.145 43.145H47.8v160.838c0 8.532 6.917 15.448 15.449 15.448s15.448-6.916 15.448-15.448V316.357h2.34c23.817-.024 43.12-19.326 43.144-43.145v-62.631a43.189 43.189 0 0 0-43.145-43.233zm12.249 105.93c-.013 6.76-5.49 12.237-12.249 12.249H43.145c-6.76-.012-12.236-5.489-12.248-12.248V210.58c.012-6.76 5.488-12.236 12.248-12.249h37.892c6.76.013 12.236 5.49 12.249 12.249v62.698zm181.539 24.696h-3.487V34.448c0-8.532-6.916-15.448-15.448-15.448s-15.449 6.916-15.449 15.448v263.526h-3.509c-23.818.024-43.12 19.326-43.144 43.145v62.742c.024 23.818 19.326 43.12 43.144 43.145h3.51v30.19c0 8.532 6.916 15.448 15.448 15.448s15.448-6.916 15.448-15.448v-30.19h3.487c23.818-.025 43.12-19.327 43.145-43.145v-62.742c-.025-23.819-19.327-43.12-43.145-43.145zm12.248 105.93c-.012 6.76-5.489 12.237-12.248 12.25h-37.893c-6.76-.013-12.236-5.49-12.248-12.25V341.12c.012-6.76 5.489-12.237 12.248-12.249h37.893c6.76.012 12.236 5.49 12.248 12.249v62.786zm180.414-295.657H464V34.448C464 25.916 457.084 19 448.552 19s-15.449 6.916-15.449 15.448v73.799h-3.508c-23.819.024-43.121 19.327-43.145 43.145v62.764c.024 23.818 19.326 43.12 43.145 43.145h3.508v219.895c0 8.532 6.917 15.448 15.449 15.448S464 485.728 464 477.196V257.3h3.487c23.818-.025 43.12-19.327 43.145-43.145v-62.764c-.025-23.818-19.327-43.12-43.145-43.145zm12.248 105.93c-.012 6.76-5.489 12.237-12.248 12.25h-37.892c-6.76-.013-12.237-5.49-12.249-12.25v-62.785c.012-6.76 5.489-12.236 12.249-12.249h37.892c6.76.013 12.236 5.49 12.248 12.249v62.786z" />
    </g>
  ),
  height: 512,
  width: 512,
})
IconIconSettings.displayName = 'IconIconSettings'
export default IconIconSettings
